<template>
    <section>
        <div id="modal" uk-modal>
            <div class="uk-modal-dialog">
                <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                    @click="hideModal"
                ></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">
                        <img
                            :src="`${images}/warning-red.svg`"
                            width="34"
                            height="34"
                            alt=""
                        />
                        {{ title }}
                    </h2>
                </div>
                <div class="uk-modal-body">
                    <p class="uk-margin-remove">
                        Are you sure want to
                        <span
                            class="uk-text-bold"
                            :class="[isVerify ? 'verify' : 'reject']"
                            >{{ isVerify ? "verify" : "reject" }}</span
                        >
                        these requests?
                    </p>
                    <p class="uk-margin-remove uk-text-bold">
                        This action cannot be undone.
                    </p>
                    <p v-if="!isMultipleMitra" class="uk-margin-remove uk-margin-medium-top">
                        Mitra Name:
                        <span class="uk-text-bold">{{ mitraName }}</span>
                    </p>
                    <p class="uk-margin-remove uk-margin-medium-top">
                        Date Range:
                        <span class="uk-text-bold">{{ startDate +' - '+ endDate}}</span>
                    </p>
                    <p class="uk-margin-remove">
                        Numbers of {{ isMultipleMitra ? 'Mitra' : 'Data' }}:
                        <span class="uk-text-bold">{{ selectedMitra.length }}</span>
                    </p>
                    <p class="uk-margin-remove">
                        Total Overtime Hours:
                        <span class="uk-text-bold">{{
                            totalOvertime + " hrs"
                        }}</span>
                    </p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                        class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
                        :class="[isVerify ? 'verify' : 'reject']"
                        type="button"
                        @click="hideModal"
                    >
                        No
                    </button>
                    <button
                        class="uk-button uk-button-primary"
                        type="button"
                        :class="[isVerify ? 'verify' : 'reject']"
                        @click="verifyOrReject"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";
export default {
    props: {
        title: {
            type: String,
            default: "Confirmation"
        },
        isVerify: {
            type: Boolean,
            default: false
        },
        selectedMitra: {
            type: Array,
            default: () => []
        },
        startDate: {
            type: String,
            default: ""
        },
        endDate: {
            type: String,
            default: ""
        },
        isMultipleMitra: {
            type: Boolean,
            default: true
        },
        mitraName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE
        };
    },
    computed: {
        totalOvertime(){
            if (this.isMultipleMitra) {
                return this.selectedMitra.reduce((accum, item) => accum + item.overtime_waiting_hrs, 0);
            } else {
                return this.selectedMitra.reduce((accum, item) => accum + item.overtime_duration_spv, 0);
            }
        }
    },
    mounted() {
        window.UIkit.modal("#modal").show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal("#modal").$destroy(true);
            this.$emit("hideModal");
        },
        verifyOrReject(){
            this.hideModal();
            const overtime_ids = 
                this.isMultipleMitra ? 
                    this.selectedMitra.map((item) => item.overtime_waiting.map((ot) => ot._id)).flat() :
                    this.selectedMitra.map((item) => item._id).flat();
            this.$emit("confirm", {data: overtime_ids});
        }
    }
};
</script>

<style scoped>
span.verify {
    color: #0275d8;
}
span.reject {
    color: #d8020f;
}
.uk-button-default.verify {
    color: #0275d8;
    border: 1px solid #0275d8;
    border-radius: 5px;
}
.uk-button-primary.verify {
    color: #ffffff;
    background-color: #0275d8;
    border: 1px solid #0275d8;
    border-radius: 5px;
}
.uk-button-default.reject {
    color: #d8020f;
    border: 1px solid #d8020f;
    border-radius: 5px;
}
.uk-button-primary.reject {
    color: #ffffff;
    background-color: #d8020f;
    border: 1px solid #d8020f;
    border-radius: 5px;
}
</style>
